import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"pa-0 ma-0 fill-height",attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VCard,{staticClass:"hero",attrs:{"elevation":"0","height":"800"}},[_c(VContainer,{staticClass:"fill-height"},[_c('menuMatrix')],1)],1)],1)],1),_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"justify":"center","align":"center"}},[_c(VImg,{staticClass:"ma-center",attrs:{"src":require("../assets/ncLogo.png"),"height":"210","contain":""}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }