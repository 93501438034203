import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},_vm._l((_vm.menuItems),function(item,index){return _c(VCol,{key:index,ref:"cards",refInFor:true,attrs:{"cols":"3","align":"center"}},[_c(VCard,{staticClass:"menu-border",attrs:{"height":"350","width":"400"},on:{"click":function($event){return _vm.openLink(item)}}},[_c(VImg,{attrs:{"contain":"","height":"300","src":item.image}}),_c(VCardTitle,{staticClass:"justify-center text-h4 font-weight-bold mt-n10 grey--text text--darken-2"},[_vm._v(" Tap To Begin ")])],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }