<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>    
  </v-app>
</template>

<script>

export default {
  name: "App",

  data: () => ({
    
  }),
  components:{
    
  },
  mounted(){
    
  }
};
</script>

<style>
html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

</style>
